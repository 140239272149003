import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { CreateOwnerAction } from '../Action/action';
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));

const CreateOwner = () => {	
	 
	const [form, setForm] = useState({ first_name: '', last_name: '', email: '', is_active: '' });
	const [validationError, setvalidationError] = useState({});
	useEffect(()=>{
					
    },[]);    
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}	
   function validate() {	   
		let first_nameError = "";
		let last_nameError 	= "";
		let emailError 		= "";		
		let is_activeError 	= "";
		
		if (form.first_name === '') {
		  first_nameError = "First name is required."
		}
		if (form.last_name === '') {
			last_nameError = "Last name is required."
		}
		if (form.email === '') {
			emailError = "Email is required."
		}		
		if (form.is_active === '') {
			is_activeError = "Status is required."
		}		
		if(form.email != '')
		{
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if(emailRegex.test(form.email) === false)
			{
				emailError = "Please enter valid email."
			}
		}
				
		if (first_nameError || last_nameError || emailError || is_activeError) {
			setvalidationError({
			  first_nameError, last_nameError, emailError, is_activeError	})
			return false
		} else {
			setvalidationError({
			  first_nameError, last_nameError, emailError, is_activeError	})
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await CreateOwnerAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}owners`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
 

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}owners`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Create Owner</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Create Owner</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}owners`} >Back</a>
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">
					 <div className="col-md-6">
						<label className="form-label" for="first_name">First name<span className="req-star">*</span></label>
						<input className={validationError.first_nameError ? 'form-control is-invalid' : 'form-control'} id="first_name" name="first_name" placeholder="Enter first name" type="text" onChange={inputHandler} />
						<div className="invalid-feedback">{validationError.first_nameError}</div>
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="last_name">Last name<span className="req-star">*</span></label>
						<input className={validationError.last_nameError ? 'form-control is-invalid' : 'form-control'} id="last_name" name="last_name" placeholder="Enter last name" type="text" onChange={inputHandler} />
						<div className="invalid-feedback">{validationError.last_nameError}</div>
					  </div> 
					  <div className="col-md-6">
						<label className="form-label" for="email">Email<span className="req-star">*</span></label>
						<input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} id="email" name="email" placeholder="Enter email address" type="text" onChange={inputHandler} autocomplete="off" />
						<div className="invalid-feedback">{validationError.emailError}</div>
					  </div>				    
					  
					  <div className="col-md-6">
						<label className="form-label" for="is_active">Status<span className="req-star">*</span></label>
						<select className={validationError.is_activeError ? 'form-control is-invalid' : 'form-control'} aria-label="select is_active" id="is_active" name="is_active" onChange={inputHandler} value={form.is_active}>
							  <option value="">Select</option>
							  <option value="0">In-Active</option>
							  <option value="1">Active</option>				
							</select>
							<div className="invalid-feedback">{validationError.is_activeError}</div>
					  </div>					  					  
					  <div className="col-12 text-end">
						<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Save</button>
					  </div>
					  					  
				  </form>                
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default CreateOwner;
