import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import Pagination from './Pagination';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import Cookies from "js-cookie";
import * as XLSX from 'xlsx';
import { getUserslistAction, getTotalUsersListAction, UserDeleteAction, UserBlockUnBlockAction, getActiveOwnersListAction, ViewUserDetailsAction } from '../Action/action';

const Users = () => {
	const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
	const [UsersList, setUsersList] 				= useState([]);
	const [page, setPage] 							= useState(1); 
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(1);
	const [spinloader, setspinloader] 				= useState(0);
	const [RolesList, setRolesList] 				= useState([]);
	const [emailFilter, SetemailFilter] 			= useState('');	
	const [statusFilter, SetstatusFilter] 			= useState('');	
	const [startIndex, setstartIndex] = useState(0);
	const [ownerFilter, SetownerFilter] 			= useState('');	
	const [owners, setowners] 						= useState([]);
	const [userDetails, setuserdetails] 			= useState([]);
	const [viewmodalopen, setviewmodalopen] 		= useState(0);
    const [ExportData, setExportData] 				= useState([]);
    useEffect(()=>{
	   setspinloader(1);
	    getTotalUsersListAPI();
        getUserslist();   
        getOwnersAPI();		        
    },[]);
    
    const SrNo = (index) => {
		return startIndex + index + 1;
	  };
    
    const getOwnersAPI = async () => {
		let res = await getActiveOwnersListAction();
		if (res.success) {
			let data = res.data;			
			setowners(data);	
		}else{
			setowners([]);	
		}
	}   
  const getTotalUsersListAPI = async () => {
        let res = await getTotalUsersListAction({email:emailFilter,status:statusFilter,owner:ownerFilter});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
		
		
    }
  
  const getUserslist = async () => {
		let res = await getUserslistAction({email:emailFilter,status:statusFilter,owner:ownerFilter,page:page});
		if (res.success) {
			let data = res.data;			
			setUsersList(data);	
			let userData = res.data;
			let xlAllData = [];
			xlAllData.push(['First Name', 'Last Name', 'Email', 'Accounts Email', 'Phone', 'Company', 'ABN','Address','Suburb','State','Postcode', 'Industry', 'Created On']);
			userData.forEach(user => {
					xlAllData.push([
						user.first_name,
						user.last_name,
						user.email,
						user.accounts_email_address,
						user.phone,
						user.company,
						user.abn,
						user.address_1,
						user.city,
						user.state,
						user.postcode,
						user.industry_name,
						moment(user.created_on).format('DD/MM/YYYY')
					]);
				});
			setExportData(xlAllData);
			setspinloader(0);
		}
		else
		{
			setUsersList([]);	
			setExportData([]);
			setspinloader(0);
		}
	}
      
  const UserDelete = async (user_id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this User!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await UserDeleteAction({'user_id':user_id});
				if (res.success) {
					toast.success(res.msg);
					getUserslist();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
   
  const handleStatus = async (id, is_active) => {
        Swal.fire({
			title: 'Are you sure?',
            text: is_active === 0 ? "Are you sure you want to disable this User?" : "Are you sure you want to enable this User?",
            icon: 'warning',        
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await UserBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                    getUserslist();
                    if(is_active==0)
                    toast.success("User disabled successfully!");
                    else
                     toast.success("User enabled successfully!");                    
                } else {
					 toast.success(res.msg);                   
                }
            }
        })
    }
 
  const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getUserslistAction({email:emailFilter,status:statusFilter,owner:ownerFilter,page:offset});
        if (res.success) {
			setstartIndex(offset);
            setUsersList(res.data);
            let userData = res.data;
			let xlAllData = [];
			xlAllData.push(['First Name', 'Last Name', 'Email', 'Accounts Email', 'Phone', 'Company', 'ABN','Address','Suburb','State','Postcode', 'Industry', 'Created On']);
			userData.forEach(user => {
					xlAllData.push([
						user.first_name,
						user.last_name,
						user.email,
						user.accounts_email_address,
						user.phone,
						user.company,
						user.abn,
						user.address_1,
						user.city,
						user.state,
						user.postcode,
						user.industry_name,
						moment(user.created_on).format('DD/MM/YYYY')
					]);
				});
			setExportData(xlAllData);
        }
        else
        {
			setUsersList([]);	
			setExportData([]);
		}

  }	
  const SetemlFilter = async (e) => {
        SetemailFilter(e.target.value);        
    }  
  const SetStFilter = async (e) => {
        SetstatusFilter(e.target.value);        
    }    
  const FilterUsers = async (e) => {
		setPage(1);	
        getTotalUsersListAPI();
        getUserslist();       
    }
  const SetOwFilter = async (e) => {
        SetownerFilter(e.target.value);        
    }     
  const ClearFilter = async (e) => {
	  SetemailFilter('');  
	  SetstatusFilter(''); 	
	  SetownerFilter(''); 	             
	  setPage(1);	
      let res = await getTotalUsersListAction({email:'',status:'',owner:'',role:''});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
      let list = await getUserslistAction({email:'',status:'',owner:'',page:1});
		if (list.success) {
			setUsersList(list.data);	
		}
		else
		{
			setUsersList([]);	
		}      
    }
    
    const ViewUser = async (user_id) => {
  
		let res = await ViewUserDetailsAction({user_id:user_id});
		if (res.success) {
			let data = res.data
			setuserdetails(data);
			setviewmodalopen(1);      
		}else{
			setuserdetails({});
			
		}
	}
  const Mdlclosebutton = async () => {
		setviewmodalopen(0); 
	}
  
  const handleExportUsers = () => {
    let wb = XLSX.utils.book_new();    
    let ws = XLSX.utils.aoa_to_sheet(ExportData); 
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, "Users.xlsx");
 };



  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        
        <Header />       
        <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Users</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Users</span>
						</div>
						<div className="col-md-6 text-end">
					 <a className="btn btn-primary createbtn" href={`${config.baseUrl}ad-user`} ><i className="fa fa-plus"></i> Create User</a>
						</div>
					</div>
					<div className="row align-items-end">
						<div className="col-lg-3 mt-3 searching">	
						<label className="form-label">User Email</label>	
						<div className="display-flex">									 
						  <input type="text" name="email" placeholder="Search..." id="input-name" data-oc-target="autocomplete-name" className="form-control" autocomplete="off" onChange={SetemlFilter} value={emailFilter}/>
						  
							</div>
						</div>
						<div className="col-lg-3 col-md-6 mt-3">	
						<label className="form-label">User Owner</label>						 
							<select className="form-control" id="owner" name="owner" onChange={SetOwFilter} value={ownerFilter}>
									  <option value="">Select Owner</option>								
									   {owners.length > 0 ? (
										owners.map((owner) => (
											<option key={owner.id} value={owner.id}>{owner.first_name} {owner.last_name}</option>
										))
								) : null} 							
									</select>
						</div>
						<div className="col-lg-2 col-md-6 mt-3">	
						<label className="form-label">Status</label>						 
							<select className="form-control" id="is_active" name="is_active" onChange={SetStFilter} value={statusFilter}>
									  <option value="">Select Status</option>								
									  <option value="1">Active</option>								
									  <option value="0">In-Active</option>								
									</select>
						</div>						
						<div className="col-lg-4 col-md-12 mt-3">
						  <button type="button" id="button-filter" className="btn btn-info" onClick={() => FilterUsers()}><i className="fa fa-search"></i> Search</button>
						  &nbsp;
						  <button type="button" id="button-filter" className="btn btn-primary" onClick={() => ClearFilter()}>Clear</button>
						  {ExportData.length > 0 ?
							  <>
							   &nbsp;&nbsp;&nbsp;
								<button className="btn btn-sm btn-primary editbtn mx-15 " onClick={handleExportUsers}> Export Excel <i className="fa-regular fa-file-excel"></i></button>
								</>
								: ""
							} 
							</div>
					</div>	
                </div>
                
                <div className="card-body">
                
                  <div className="table-responsive">
                    <table className="table mb-0 users-list-table">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>Sr.</th>
                          <th>User Name</th>
                          <th>Email</th>                          
                          <th>Business name</th>                          
                          <th>Suburb and State</th>                          
                          <th>last activity</th>                           
                          <th>Status</th>   
                          { loginData.role == config.role_admin ? (<><th>Action</th></>):null  }
						</tr>
                      </thead>
                      <tbody>
                        { spinloader==0 ? (
						UsersList.length > 0  ?
							UsersList.map((xuser,index)=>{
								let rowClass = '';
								if (xuser.days_since_registration <= config.days_since_registration) {
									rowClass = 'greenCLS';
								}else if (
									xuser.days_since_registration > config.days_since_registration &&
									(xuser.currentMonthSpent === 1 || xuser.prevMonthSpent === 1 )
								) {
									rowClass = 'greenCLS';
								}else if (
									xuser.days_since_registration > config.days_since_registration &&
									xuser.currentMonthSpent === 0 &&
									xuser.prevMonthSpent === 0 &&
									xuser.thirdPrevMonthSpent === 1
								) {
									rowClass = 'yellowCLS';
								} else if (
									xuser.days_since_registration > config.days_since_registration &&
									xuser.currentMonthSpent === 0 &&
									xuser.prevMonthSpent === 0 &&
									xuser.thirdPrevMonthSpent === 0
								) {
									rowClass = 'redCLS';
								}
								return(
									<tr key={index}>
										<td>{SrNo(index)}</td>
										<td  onClick={() => ViewUser(xuser.id)} title="View User" className={`${rowClass}`}><span>{xuser.first_name+' '+xuser.last_name}</span></td>
										<td onClick={() => ViewUser(xuser.id)} title="View User" className={`wordbreak`} >{xuser.email}</td>										
										<td onClick={() => ViewUser(xuser.id)} title="View User">{xuser.company}</td>										
										<td onClick={() => ViewUser(xuser.id)} title="View User">{xuser.city} {xuser.state}</td>	
										<td onClick={() => ViewUser(xuser.id)} title="View User">
										  {xuser.last_activity_date
											? new Date(xuser.last_activity_date).toLocaleDateString('en-GB')
											: 'No Activity'}
										</td>						
										<td>
										{ loginData.role == config.role_admin ? (<>
												<div className="form-check form-switch">
												  <input
													className="form-check-input"
													id={`userstatus_${index}`}
													type="checkbox"
													checked={xuser.is_active === 1}
													onChange={() => handleStatus(xuser.id, xuser.is_active === 1 ? 0 : 1)}
												  />
											</div>
											</>):(<>
											<div className="form-check form-switch" title={xuser.is_active === 1 ? 'Active' : 'In-active'}>
											  <input
												className="form-check-input"
												id={`userstatus_${index}`}
												type="checkbox"
												checked={xuser.is_active === 1}
												/>
											</div>
											
											</>) }
										
										
										
										</td>	
											{ loginData.role == config.role_admin ? (<>
												<td className="td-action">
													<Link to={`${config.baseUrl}edit-user/` + xuser.id}>
															<button className='btn btn-primary editbtn'><i className="far fa-edit"></i> Edit</button>
														</Link>
														&nbsp;&nbsp; 
													<button type="button" className="btn btn-primary deletebtn" onClick={() => UserDelete(xuser.id)}><i className='fa fa-trash'></i> Delete</button>
												</td>
											</>):null }
										
										
									</tr>
								)
							})
							:
							(
								<tr><td colspan="7" className="text-center">No user found.</td></tr>
							)
						
						):
							(
								<tr><td colspan="7" className="text-center"><img className="loaderImg search-loader" src={config.baseUrl + "images/loader.gif"} height={20} width={20} /></td></tr>
							) }
                      
                      </tbody>
                    </table>
                  </div>
                   {totalPages > 0 ?										  
						 <Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
						 : ''
					 }	
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      
      
      <div id="viewUserModel" className={viewmodalopen === 0 ? "modal fade cart-modal mc-popup" : " mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgb(40 40 40 / 61%)', display: viewmodalopen === 0 ? 'none' : 'block' }}
                data-backdrop="false">            
					  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="modal-title" id="oderheading">User Details</h5>
							<button type="button" onClick={Mdlclosebutton} className="close" data-dismiss="modal" aria-label="Close">
							  <span aria-hidden="true">×</span>
							</button>
						  </div>
						  <div className="modal-body">
							  <div className="row">
								 <div className="mb-3 col-md-4">
									<label className="form-label fw-bold">User Name:</label>
									<p className="form-control-plaintext">{userDetails.first_name} {userDetails.last_name}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Email:</label>
									<p className="form-control-plaintext">{userDetails.email}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Account Email:</label>
									<p className="form-control-plaintext">{userDetails.accounts_email_address}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">ABN:</label>
									<p className="form-control-plaintext">{userDetails.abn}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Phone:</label>
									<p className="form-control-plaintext">{userDetails.phone}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Company:</label>
									<p className="form-control-plaintext">{userDetails.company}</p>
								</div>								
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Owner:</label>
									<p className="form-control-plaintext">{userDetails.ownerfname ? userDetails.ownerfname+' '+userDetails.ownerlname   : '-'}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Minimum order total:</label>
									<p className="form-control-plaintext">{userDetails.minimum_order_total}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Average monthly spend:</label>
									<p className="form-control-plaintext">{userDetails.monthly_spend_title}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Free shipping:</label>
									<p className="form-control-plaintext"> {userDetails.free_shipping==1 ? "Yes"    : 'No'}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Minimum ammount for free shipping:</label>
									<p className="form-control-plaintext">{userDetails.minimum_amout_for_free_shipping}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Override Global Free Shipping:</label>
									<p className="form-control-plaintext"> {userDetails.override_global_free_shipping==1 ? "Yes"    : 'No'}</p>
								</div>
								<div className="mb-3 col-md-4">
									<label className="form-label fw-bold">Tiered group:</label>
									<p className="form-control-plaintext">{userDetails.tairgroup}</p>
								</div>
								<div className="mb-3 col-md-8">
									<label className="form-label fw-bold">Address:</label>
									<p className="form-control-plaintext">{userDetails.address_1} {userDetails.city} {userDetails.state} {userDetails.postcode}</p>
								</div>
							</div>	
						  </div>
						  <div className="modal-footer">
							<a type="button"  onClick={Mdlclosebutton} className="close" data-dismiss="modal" aria-label="Close">Close</a>
						  </div>
						</div>
					  </div>
					</div>	
      
      
      
      
      <Footer />
      </div>
    </>
  )

}
export default Users;
