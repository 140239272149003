import './App.css';
import config from "./coreFIles/config";
import Login from './component/login';
import Dashboard from "./component/dashboard";
import Categories from "./component/categories";
import Subcategory from "./component/subcategories";
import Filters from "./component/filters";
import Users from "./component/users";
import CreateUser from "./component/createuser";
import EditUser from "./component/edituser";
import Settings from './component/Settings'
import Products from './component/products'
import Orders from './component/orders'
import Editproduct from './component/editproduct'
import Quotes from './component/quotes';
import Addquotes from './component/addquotes';
import Createorder from './component/createorder';
import Editquote from './component/editquote';
import Owners from './component/owners';
import CreateOwner from "./component/createowner";
import EditOwner from "./component/editowner";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />         
          <Route path={`${config.baseUrl}categories`} element={<Categories />} />  
          <Route path={`${config.baseUrl}sub-category/:id`} element={<Subcategory />} />        
          <Route path={`${config.baseUrl}filters`} element={<Filters />} />  
           <Route path={`${config.baseUrl}owners`} element={<Owners />} />        
          <Route path={`${config.baseUrl}ad-owner`} element={<CreateOwner />} /> 
          <Route path={`${config.baseUrl}edit-owner/:id`} element={<EditOwner />} />       
          <Route path={`${config.baseUrl}users`} element={<Users />} />        
          <Route path={`${config.baseUrl}ad-user`} element={<CreateUser />} /> 
          <Route path={`${config.baseUrl}edit-user/:id`} element={<EditUser />} /> 
          <Route path={`${config.baseUrl}settings`} element={<Settings />}  />         
          <Route path={`${config.baseUrl}products`} element={<Products />}  />         
          <Route path={`${config.baseUrl}orders`} element={<Orders />}  />         
          <Route path={`${config.baseUrl}edit-product/:id`} element={<Editproduct />}  />  
          <Route path={`${config.baseUrl}quotes`} element={<Quotes />}  />   
          <Route path={`${config.baseUrl}create-quote`} element={<Addquotes />}  /> 
          <Route path={`${config.baseUrl}edit-quote/:id`} element={<Editquote />}  />             
          <Route path={`${config.baseUrl}create-order`} element={<Createorder />}  />          
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
